'use strict';

/**
 * Mega Menu Header
 */

const $exploreMobile = $('#exploreMobile,#pdButton,#topicsTitle');
const $navHeader = $('#navHeader');

$navHeader.on('click', '#nav-icon', function () {
	$(this).toggleClass('open');
});

$navHeader.on('click', 'a.mobile-category', function () {
	const $this = $(this);
	const $navLinkSiblings = $this.parent().parent().siblings('.nav-link');
	const $icon = $this.children('i');
	const $parent = $this.parent().parent();
	const ariaExpanded = $this.attr('aria-expanded');

	if (ariaExpanded === "false") {
		$exploreMobile.addClass('hidden-lg-down');
		$navLinkSiblings.addClass('hidden-lg-down');
		$parent.addClass('gray-bg').removeClass('border-top');
		$icon.removeClass('fa-chevron-right').addClass('fa-chevron-left text-white').parent().removeClass('pl-3').siblings().removeClass('p-0 font-weight-normal').addClass('pl-4 text-white font-weight-bold').parent().addClass("flex-row-reverse");
	} else if (ariaExpanded === "true") {
		$exploreMobile.removeClass('hidden-lg-down');
		$navLinkSiblings.removeClass('hidden-lg-down');
		$icon.addClass('fa-chevron-right').removeClass('fa-chevron-left text-white').parent().addClass('pl-3').siblings().removeClass('pl-4 text-white font-weight-bold').addClass('p-0 font-weight-normal').parent().removeClass("flex-row-reverse");
		$parent.removeClass('gray-bg').addClass('border-top');
	}
});

$navHeader.on('click keydown', 'div.link-icon', function (event) {
	if (event.type === 'keydown' && event.which !== 13) {
		return;
	}

	const $this = $(this);
	const $ul = $this.siblings('ul');
	const $siblingsUl = $this.parent().siblings().children('ul');
	const $siblingsDiv = $this.parent().siblings().children('div');
	const ariaExpanded = $this.attr('aria-expanded');

	if (ariaExpanded === 'false') {
		if ($siblingsUl.hasClass('d-flex')) {
			$siblingsUl.removeClass('d-flex').addClass('d-none');
			$siblingsDiv.attr('aria-expanded', 'false');
		}
		$ul.addClass('d-flex').removeClass('d-none');
		$this.attr('aria-expanded', 'true');
		$ul.children('li').css('padding', '0.25rem 1.5rem');
	} else {
		if ($siblingsUl.hasClass('d-flex')) {
			$siblingsUl.removeClass('d-flex').addClass('d-none');
			$siblingsDiv.attr('aria-expanded', 'false');
			$ul.removeClass('d-flex').addClass('d-none');
		} else {
			$ul.removeClass('d-flex').addClass('d-none');
		}
		$this.attr('aria-expanded', 'false');
	}
});

// Hide account disclaimer modal when "continue" is clicked
$('#header-group').on('click', '#accountModal .modal-footer > a', function () {
	$(this).parents('#accountModal').modal('hide');
})

// Function to rotate the header banner messages if there are multiple children
$(function () {
	function rotateContents() {
		var $container = $('.header-messages-rotate');
		var $contents = $container.children(); // Get all immediate children

		if ($contents.length > 1) {
			var currentIndex = 0;

			function showNextContent() {
				$contents.not($contents.eq(currentIndex)).hide();
				$contents.eq(currentIndex).fadeOut(500, function () {
					currentIndex = (currentIndex + 1) % $contents.length;
					$contents.eq(currentIndex).fadeIn(500);
				});
			}

			// Rotate contents every 5 seconds
			setInterval(showNextContent, 5000);
		}
	}

	// Initially hide all messages except the first one
	$('.header-messages-rotate > :not(:first-child)').hide();

	rotateContents();
});

$(function () {
	$('.close-button-white span').on('click', function () {
		$('.new-header').hide();
	});
});