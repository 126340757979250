'use strict';

function getProductItemObject(product) {
    const variation = product.variationAttributes?.find(attr => attr.attributeId === "date")?.displayValue;

    return {
        item_name: product.productName,
        item_id: product.id,
        price: product.price?.sales?.value,
        item_brand: 'CPAO',
        item_category: product.primaryCategory,
        item_category2: product.eventType?.value,
        item_variant: variation,
        quantity: 1
    };
}

function onAddToCart(data) {
    window.dataLayer = window.dataLayer || [];

    const currencyCode = $('body').data('currency');
    const pliUUID = data.pliUUID;
    const itemAdded = data.cart.items.find(item => item.UUID === pliUUID);

    const dataLayerObj = {
        event: 'add_to_cart',
        ecommerce: {
            currency: currencyCode,
            value: itemAdded.price?.sales?.value,
            items: [getProductItemObject(itemAdded)]
        },
        member_id: typeof member_id !== 'undefined' ? member_id : ''
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(dataLayerObj);
}

function onRemoveFromCart(data) {
    const currencyCode = $('body').data('currency');
    const itemRemoved = data.productRemoved;

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currencyCode: currencyCode,
            value: itemRemoved.price?.sales?.value,
            items: [getProductItemObject(itemRemoved)]
        },
        member_id: typeof member_id !== 'undefined' ? member_id : ''
    });
}

function onAddPaymentInfo(data) {
    window.dataLayer = window.dataLayer || [];
    const currencyCode = $('body').data('currency');
    const paymentInstruments = data.billing?.payment?.selectedPaymentInstruments;
    var products = data.items.items.map(getProductItemObject)

    var ecommerceObj = {
        currency: currencyCode,
        value: data.totals?.totalGrossPrice,
        items: products,
    }

    for (let i = 0; i < paymentInstruments.length; i++) {
        if (i === 0) {
            ecommerceObj.payment_type = paymentInstruments[i].paymentMethod === 'WALLET' ? paymentInstruments[i].walletPaymentType : paymentInstruments[i].paymentMethod;
        } else {
            ecommerceObj["payment_type" + (i + 1)] = paymentInstruments[i].paymentMethod === 'WALLET' ? paymentInstruments[i].walletPaymentType : paymentInstruments[i].paymentMethod;
        }
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: ecommerceObj,
        member_id: typeof member_id !== 'undefined' ? member_id : ''
    })
}

function onProductClick(name, id, price, primaryCategory, categories) {
    window.dataLayer = window.dataLayer || [];

    let item = {
        item_id: id,
        item_name: name,
        price: !isNaN(parseFloat(price)) ? parseFloat(price) : 0,
        quantity: 1,
        item_brand: "CPAO",
        item_category: primaryCategory
    };

    categories = categories.split('|');
    let catIndex = 2;
    categories.forEach(category => {
        if (category !== primaryCategory) {
            item["item_category" + catIndex] = category;
            catIndex++;
        }
    })

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            items: [item]
        },
        member_id: typeof member_id !== 'undefined' ? member_id : ''
    })
}

module.exports = {
    onAddToCart: onAddToCart,
    onRemoveFromCart: onRemoveFromCart,
    onAddPaymentInfo: onAddPaymentInfo,
    onProductClick: onProductClick
}